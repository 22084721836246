import axios from 'axios';

const Service = () => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    let loginRoute = null
    if(global.app.$router) {
        loginRoute = global.app.$router.currentRoute.name;
    }

    const token = localStorage.getItem('webToken');
    if(token && loginRoute !== 'login') {
            headers.Authorization = `Bearer ${token}`;
    }

    const instance = axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_API_URL,
        headers
    });

    instance.interceptors.request.use(function (config) {
       global.app.$store.commit('common/SET_LOADING', true);
        global.app.$store.commit("SET_ERRORS", {}, {root: true});
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    instance.interceptors.response.use(response => {
        global.app.$store.commit('common/SET_LOADING', false);

        const data = response.data;
        if (!data?.display) {
                console.log('')
        }else{
            global.app.$toastr.success(data.message);
        }
        return data;
    }, error => {
        global.app.$store.commit('common/SET_LOADING', false);

        if(error.response === undefined) {
            global.app.$toastr.error('No response received. We are regretted for inconvenience. please try later');
            return Promise.reject(error);
        }

        if(401 === error.response.status) {
            global.app.$router.push({name: 'login'});
            return  Promise.reject(error);
        }

        const data = error.response.data;
        if (data?.display) {
            global.app.$toastr.error(data.message);
        }

        if (400 === error.response.status) {
            global.app.$store.commit("SET_ERRORS", data.errors);
        }
        return Promise.reject(data);
    });

    return instance;
}

export default Service;
