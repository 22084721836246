<template>
  <div>
    <section class="player-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
            <div
                class="player-content d-flex justify-content-start align-items-center"
            >
              <div class="player-jercy">
                <img :src="this.rider_default_data?.user?.jersey"
                     v-if="this.rider_default_data?.user?.jersey" />
              </div>
              <div class="player-team-info">
                <div class="p-name">
                  <img class="flg" width="42"  :src="this.rider_default_data?.user?.country"
                       v-if="this.rider_default_data?.user?.country" />

                  {{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['PlayerFirstName'] }}

                  {{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['PlayerLastName'] }}
                </div>
                <div class="p-team">
                  TEAMS : <span class="catg txtClrSkyBlue">{{ this.rider_default_data?.user?.team }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
            <div class="">
              <div class="editProfileWrpr">
                <div
                    class="setBtn d-flex justify-content-end align-items-center"
                >
                  <div>
                    <router-link :to="{ name: 'profile' }" class="editBtn">
                      <i class="fa-solid fa-pen"></i> EDIT PROFILE

                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-level-div">
          <div class="row d-flex align-items-center ">
            <div class="col-md-6">
              <div class="catBtnWrpr">
                <span class="gen txtClr">
                  {{ this.genders[this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['Gender']] }}
                </span>
                <span class="cat-list bgClrRed" v-if="this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['PlayerCategoryId']">
                  CAT {{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['PlayerCategoryId'] }}
                </span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="player-skills d-flex justify-content-between align-items-end">
                <div class="">
                  <p class="title">LEVEL</p>
                  <div><span class="num-count txtClrOrg">{{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['PlayerLevel'] }}</span></div>
                </div>
                <div class="">
                  <p class="title">FTP  </p>
                  <div><span class="num-count">{{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['PlayerFtp'] }} W</span></div>
                </div>

                <div class="" v-if="this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['VerifiedWeight']">
                  <p class="title">WEIGHT <img src="/assets/images/activities/icn_check.png" /></p>
                  <div><span class="num-count">{{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['VerifiedWeight'] }} KG</span></div>
                </div>

                <div class="" v-else >
                  <p class="title">WEIGHT </p>
                  <div><span class="num-count">
                   {{this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['Weight'] }}
                   KG</span></div>
                </div>


                <div class="" v-if="this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['VerifiedHeight']">
                  <p class="title">HEIGHT <img src="/assets/images/activities/icn_check.png" /></p>
                  <div><span class="num-count">{{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['VerifiedHeight'] }} CM</span></div>
                </div>

                <div class="" v-else>
                  <p class="title">HEIGHT </p>
                  <div><span class="num-count">{{this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['HeightCm'] }} CM</span></div>
                </div>


                <div class="" v-if="this.rider_default_data?.user?.age">
                  <p class="title">AGE</p>
                  <div><span class="num-count">{{ this.rider_default_data?.user?.age }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="overview-tab">
      <div class="container">
        <!--tabs buttons start-->

        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-0">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                    class="nav-link"
                    style="cursor:pointer;"
                    :class="{ 'active': isActiveProfileTab }"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    @click="makeTabFunc($event)"
                >
                  Overview
                </a>

                <a
                    class="nav-link"
                    :class="{ 'active': isActiveActivityTab }"
                    id="nav-contact-tab3"
                    style="cursor:pointer;"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-activities"
                    type="button"
                    role="tab"
                    aria-controls="nav-activities"
                    aria-selected="false"
                    @click="makeTabFunc($event)"
                >
                  ACTIVITIES
                </a>
                <a
                    class="nav-link"
                    :class="{ 'active': isActiveInGameShopTab }"
                    id="nav-in-game-shop-tab3"
                    style="cursor:pointer;"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-in-game-shop"
                    type="button"
                    role="tab"
                    aria-controls="nav-in-game-shop"
                    aria-selected="false"
                    @click="makeTabFunc($event)"
                >
                  In Game Shop
                </a>
              </div>
            </nav>
          </div>
        </div>

        <!--tabs button end-->
      </div>
    </section>
    <section class="tab-content">
      <div class="container main-tab-container p-0">
        <div class="tab-content-wrpr">
          <div class="tab-content" id="nav-tabContent">
            <OverView :is-active="isActiveProfileTab"
                      :third-party-data="this.rider_default_data?.third_party_data"
                      :user-data="this.rider_default_data?.user?.userData" :total-events="this.user_total_event_count" />
            <div
                class="tab-pane fade"
                :class="{ 'show active': isActiveActivityTab }"
                id="nav-activities"
                role="tabpanel"
                aria-labelledby="nav-activities-tab"
                tabindex="0"
            >

              <section v-show="!showDetail" class="dark-graph">
                <div class="container">
                  <div class="tab-graph">
                    <div class="tbGraph-btn d-flex justify-content-between align-items-center">
                      <div>
                        <h3>{{tabTitle}}</h3>
                      </div>
                      <div>
                        <ul
                            class="nav nav-pills"
                            id="pills-tab"
                            role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                                style="cursor:pointer;"
                                class="nav-link active"
                                @click="selectTab('POWER Curve')"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                              POWER
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                                style="cursor:pointer;"
                                class="nav-link"
                                @click="selectTab('POWER CURVE (W/KG)')"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                              W/KG
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                      >
                        <div class="graph-wrpr-box">
                          <LineChartGenerator :chart-options="chartOptionsCompute" :chart-data="chartDataCompute"   />
                        </div>
                        <div class="checkBoxes d-flex justify-content-center">
                          <label>
                            <i class="fa-solid fa-circle"></i> All TIME</label
                          >

                          <label v-if="firstGraphDate" >
                            <i class="fa-solid fa-circle txtClrlightRed"></i>
                            {{ firstGraphDate }}</label>

                          <label v-if="secondGraphDate"
                          ><i class="fa-solid fa-circle txtClrSkyBlue"></i>
                            {{ secondGraphDate }}</label>
                        </div>
                      </div>
                      <div
                          class="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                      >
                        <div class="graph-wrpr-box">
                          <LineChartGenerator :chart-options="chartOptionsWeightPerKgCompute" :chart-data="chartDataWeightPerKgCompute"    />
                        </div>
                        <div class="checkBoxes d-flex justify-content-center">
                          <label >
                            <i class="fa-solid fa-circle"></i> All TIME</label
                          >

                          <label v-if="firstGraphDate" >
                            <i class="fa-solid fa-circle txtClrlightRed"></i>
                            {{ firstGraphDate }}</label>

                          <label v-if="secondGraphDate"
                          ><i class="fa-solid fa-circle txtClrSkyBlue"></i>
                            {{ secondGraphDate }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div v-show="!showDetail"
                   class="searchMainWrpr container">
                <div class="row justify-content-between">
                  <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3" >
                    <div class="select my-1" style="display:none;">
                      <select name="format"   v-model="type">
                        <option  value="">ALL ACTIVITIES</option>
                        <option value="ride">Ride</option>
                        <option value="workout">Workout</option>
                        <option value="event">Event</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                    <div class="select my-1 float-end">
                      <select name="format"    v-model="sortDate">
                        <option value="DESC" >SORT BY DATE DESC</option>
                        <option value="ASC" >SORT BY DATE ASC</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>


              <div v-if="this.activities.results.length === 0">
                <div class="msg-txt"  >
                  <p>-  All your activities will be displayed here  -</p>
                </div>
              </div>

              <div v-else>

                <div v-show="showDetail">


                  <div class="activeRaceClub container">
                    <div class="day-selected">{{ this.selectedDate | moment("DD/MM/YYYY")  }}</div>
                    <h4>
                      <span class="txtClrSkyBlue"><a href="javascript:void(0)" @click="hideDetail()">ACTIVITIES/</a></span>
                      <span class="txtClrwt">{{ this.single_ride_data?.header_detail['title']  }} |</span>
                      <span class="txtfontNormal">{{ this.single_ride_data?.header_detail['routeName'] }}</span>

                    </h4>
                    <!--table start-->

                    <!--table end-->
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                        <div
                            class="player-moniter d-flex justify-content-between align-items-end"
                        >
                          <div class="item">
                            <p class="title">AVG. Watts</p>
                            <div><span class="num-count">{{ this.single_ride_data?.header_detail['normalizePower'] }} w</span></div>
                          </div>
                          <div class="item">
                            <p class="title">TOTAL WORK</p>
                            <div><span class="num-count">{{ this.single_ride_data?.header_detail['totalWorkout'] }} kj</span></div>
                          </div>
                          <div class="item">
                            <p class="title">TRAINING LOAD</p>
                            <div><span class="num-count">{{ this.single_ride_data?.header_detail['traningLoad'] }}</span></div>
                          </div>
                          <div class="item">
                            <p class="title">INTENSITY</p>
                            <div><span class="num-count">{{ this.single_ride_data?.header_detail['intensity'] }}%</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-6">
                        <div
                            class="btn-rece d-flex justify-content-end align-items-end"
                        >
                          <a href="#" class="gen txtClr">{{ this.genders[this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['Gender']] }}</a
                          ><a href="#" class="cat-list bgClrRed" v-if="this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['PlayerCategoryId']">CAT {{ this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerProfileStruct']['PlayerCategoryId'] }}</a>
                          <button class="btnCircle" type="button" @click="downloadFile(selectedFileId)">
                            <img src="/assets/images/activities/downloadCircle.png" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="info-align">
                      <div class="row d-flex align-items-center">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-6">
                          <div class="info-activ-person d-flex justify-content-start align-items-end" style="display: none!important;">
                            <div>{{ this.single_ride_data?.header_detail['elevation'] }} m</div>
                            <div>{{ this.single_ride_data?.header_detail['distance'] }} KM</div>
                            <div>{{ this.single_ride_data?.header_detail['watt'] }} w</div>
                            <div>{{ this.single_ride_data?.header_detail['wattPerKg'] }} w/KG</div>
                            <div>{{ this.single_ride_data?.header_detail['heartrate'] }} BPM</div>
                            <div>{{ this.single_ride_data?.header_detail['rideDuration'] }}</div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-6">



                        </div>

                      </div>
                    </div>
                  </div>


                  <!--graph start-->
                  <section class="graphWrpr">
                    <div class="container p-0 mb-5">
                      <div class="">
                        <div
                            class="time-row d-flex justify-content-between"

                        >
                          <span class="subgraph-title">elevation map</span>
                          <div class="time-note">
                            TIME {{ hoverElevationDate }}
                          </div>
                        </div>
                        <div class="grph-text d-flex justify-content-between">
                          <div
                              class="graph-box-wrpr"

                          >
                            <LineChartGenerator
                                class="overContainer"
                                id="elevationGraph"
                                ref="elevationGraph"
                                :height="220"
                                :chart-options="chartOptionsElevation"
                                :chart-data="chartDataElevation"
                            />
                          </div>
                          <div class="value-text">
                            <div
                                class="graphText"
                                style="text-align: center !important;"
                            >
                              <p>{{ hoverElevationTime }} MIN</p>
                              <p>{{ hoverElevation }} KM</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="margin-left-right">
                        <div class="time-row d-flex justify-content-between">
                          <span class="subgraph-title">Power</span>
                          <span
                          >AVG <strong> {{ this.avgPower }} w</strong> | MAX
                            <strong> {{ this.maxPower }} w</strong> | MIN
                            <strong> {{ this.minPower }} w</strong>
                          </span>
                        </div>
                        <div class="grph-text d-flex justify-content-between">
                          <div class="graph-box-wrpr">
                            <LineChartGenerator
                                class="overContainer"
                                ref="powerGraph"
                                :height="220"
                                :chart-options="chartOptionsPower"
                                :chart-data="chartDataPower"
                            />
                          </div>
                          <div class="value-text">
                            <div
                                class="graphText"
                                style="text-align: center !important;"
                            >
                              {{ hoverPower }} <span class="txtClrBlack">w</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="margin-left-right">
                        <div class="time-row d-flex justify-content-between">
                          <span class="subgraph-title">Speed</span>
                          <span>
                            AVG <strong> {{ this.avgSpeed }} KM/H </strong> | MAX
                            <strong> {{ this.maxSpeed }} KM/H </strong>
                          </span>
                        </div>
                        <div class="grph-text d-flex justify-content-between">
                          <div class="graph-box-wrpr">
                            <LineChartGenerator
                                class="overContainer"
                                ref="speedGraph"
                                :height="220"
                                :chart-options="chartOptionsSpeed"
                                :chart-data="chartDataSpeed"
                            />
                          </div>
                          <div class="value-text">
                            <div
                                class="graphText"
                                style="text-align: center !important;"
                            >
                              {{ hoverSpeed }} KM/H
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="margin-left-right"    >
                        <div class="time-row d-flex justify-content-between">
                          <span class="subgraph-title">Heart Rate</span>
                          <span
                          >AVG <strong> {{ this.avgHeartRate }} BPM</strong> | MAX
                            <strong>{{ " " + this.maxHeartRate }} BPM</strong> | MIN
                            <strong> {{ this.minHeartRate }} BPM</strong></span
                          >
                        </div>
                        <div class="grph-text d-flex justify-content-between">
                          <div class="graph-box-wrpr">
                            <LineChartGenerator

                                class="overContainer"
                                ref="heartGraph"
                                :height="220"
                                :chart-options="chartOptionsHeartRate"
                                :chart-data="chartDataHeartRate"
                            />
                          </div>
                          <div class="value-text">
                            <div
                                class="graphText"
                                style="text-align: center !important;"
                            >
                              {{ hoverHeartRate }}
                              <span class="txtClrBlack">BPM</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="margin-left-right">
                        <div class="time-row d-flex justify-content-between">
                          <span class="subgraph-title">Cadence</span>
                          <span
                          >AVG <strong>{{ this.avgCadence }} RPM</strong> | MAX
                            <strong> {{ this.maxCadence }} RPM</strong>
                          </span>
                        </div>
                        <div class="grph-text d-flex justify-content-between">
                          <div class="graph-box-wrpr">
                            <LineChartGenerator
                                class="overContainer"
                                ref="cadenceGraph"
                                :height="220"
                                :chart-options="chartOptionsCadence"
                                :chart-data="chartDataCadence"
                            />
                          </div>
                          <div class="value-text">
                            <div
                                class="graphText"
                                style="text-align: center !important;"
                            >
                              {{ hoverCadence }}
                              <span class="txtClrBlack">RPM</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!--graph end-->
                  <br>
                  <br>
                  <br>

                </div>

                <div v-show="!showDetail">

                  <div class="results table-responsive container">
                    <table class="table align-middle">
                      <thead class="table-dark">
                      <tr>
                        <th>DATE</th>
                        <th>RIDE</th>
                        <th>DIST</th>
                        <th>ELEV</th>
                        <th>W <span>(avg)</span></th>
                        <th>W/KG <span>(avg)</span></th>
                        <th>HEART <span>(avg)</span></th>
                        <th>TIME</th>
                        <th class="btn-w">ANALYSIS</th>
                        <!--                        <th class="btn-w">COMPARE</th>-->
                        <th class="btn-w">DOWNLOAD</th>
                      </tr>
                      </thead>
                      <tbody class="table-light">
                      <tr  v-for="(item, index) in this.activities.results" :key="index">

                        <td>{{ item.date | moment("DD/MM/YYYY") }}</td>
                        <td class="dblText">
                          <div class="d-flex align-items-center">
                            <div class="">

                              <img  style="width: 35px"
                                    class="mx-2" v-if="item.type === 'event' && item.sportType === 'cycling'"   src="/assets/images/activities/ActivityCal.png" />
                              <img  style="width: 35px"
                                    class="mx-2" v-else-if="item.type === 'ride' && item.sportType === 'cycling'" src="/assets/images/activities/ActivityCycle.png" />
                              <img  style="width: 35px"
                                    class="mx-2" v-else-if="item.type === 'workout' && item.sportType === 'cycling'" src="/assets/images/activities/ActivityBars.png" />

                              <img  style="width: 35px"
                                    class="mx-2" v-else-if="item.type === 'event' && item.sportType === 'running'"   src="/assets/images/activities/ActivityType_run.png" />
                              <img  style="width: 35px"
                                    class="mx-2" v-else-if="item.type === 'ride' && item.sportType === 'running'" src="/assets/images/activities/ActivityType_run.png" />
                              <img  style="width: 35px"
                                    class="mx-2" v-else-if="item.type === 'workout' && item.sportType === 'running'" src="/assets/images/activities/ActivityType_run.png" />

                            </div>

                            <div class="">
                              <strong>{{ item.title }}</strong>
                              <br />
                              {{ item.routeName }}
                            </div>


                          </div>
                        </td>

                        <td> {{ item?.distance.toFixed(2) }} KM</td>
                        <td>{{ item?.elevation }} M</td>
                        <td>{{ item.watt }} w</td>
                        <td>{{ item.wattPerKg }} w/kg</td>
                        <td>{{ item.heartrate }} bpm</td>
                        <td>{{  item.rideDuration  }} </td>

                        <td><button     style="cursor: pointer"  type="button" class="btnAnalysis" @click="getSingleActivity(item.activityFileId,item.date)" ></button></td>
                        <td  style="display:none;" >


                          <input  type="checkbox" v-model="graphFileIds" style="display: none"
                                  :value="item.activityFileId" class="input_class_checkbox"  />

                          <button      style="cursor: pointer" type="button" class="btnCompare input_compare" :class="graphFileIds.indexOf(item.activityFileId) !== -1 ? 'active':''"   @click="addToGraph($event,item.activityFileId)"></button>
                        </td>
                        <td><button      style="cursor: pointer" type="button" class="btnDownload" @click="downloadFile(item.activityFileId)"></button></td>

                      </tr>

                      </tbody>
                    </table>
                  </div>

                  <div class="contrLoadMore" v-if="!finished">
                    <button type="button" @click="getAllActivities()" class="ldmore">LOAD MORE</button>
                  </div>
                </div>

              </div>

            </div>


            <GameShop :is-active="isActiveInGameShopTab"
                      :user-gems="this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['Gems']"
                      :user-coins="this.rider_default_data?.user?.userData['data']['PlayerData']['PlayerPersonalStruct']['Coins']" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>


import {mapGetters} from "vuex";
import moment from 'moment';
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'
import {
  CategoryScale,
  Chart as ChartJS,
  Chart,
  Interaction,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  Tooltip
} from 'chart.js'
import OverView from '@/components/rider/OverView.vue'
import {CrosshairPlugin, Interpolate} from 'chartjs-plugin-crosshair';
import GameShop from "@/components/game_shop/GameShop";


ChartJS.register(
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)
Chart.register(LineController, CrosshairPlugin,...registerables);

Interaction.modes.interpolate = Interpolate

export default {
  head: {
    link: [
      { rel: 'stylesheet', href: 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css',  },
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css',  },
      { rel: 'stylesheet', href: '/assets/css/activities/style.css',  },
    ]
  },
  name: 'PlayerActivities',
  data() {
    return {
      tabTitle: "POWER CURVE",
      selectedDate: "",
      hoverElevationDate: "00:00:00",
      hoverElevationTime: "0",
      selectedFileId: "",
      hoverElevation: 0,
      hoverPower: 0,
      hoverSpeed: 0,
      hoverCadence: 0,
      hoverHeartRate: 0,
      showDetail: false,
      genders: ["MEN", "FEMALE", "Both"],
      firstGraphDate: "",
      secondGraphDate: "",
      graphFileIds: [],
      compareDate: [],
      maxElevation: 0,
      avgElevation: 0,
      minElevation: 0,
      avgPower: 0,
      minPower: 0,
      maxPower: 0,
      avgSpeed: 0,
      maxSpeed: 0,
      avgHeartRate: 0,
      maxHeartRate: 0,
      minHeartRate: 0,
      avgCadence: 0,
      maxCadence: 0,
      powerGraphHop: 1,
      powerGraphGap: 15,
      minElevationY:0,
      chartDataDefault: {
        labels: [],
        datasets: [
          {
            label: "Power",
            data: [],
            fill: false,
            borderColor: "#ffffff",
            backgroundColor: "#ffffff",
            borderWidth: 2,
            tension: 0.5,
            bezierCurve: false,
          },
          {
            label: "Power",
            data: [],
            fill: false,
            borderColor: "#FF6C01",
            backgroundColor: "#FF6C01",
            borderWidth: 2,
          },
          {
            label: "Power",
            data: [],
            fill: false,
            borderColor: "#00B2E3",
            backgroundColor: "#00B2E3",
            borderWidth: 2,
          },
        ],
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            mode: "index",
            intersect: false,

            callbacks: {
              title: function(tooltipItem) {
                return (
                    "Time: "+ tooltipItem[0]?.raw?.x || ""
                );
              },

            }

          },
          crosshair: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            id: "x-axis-1",
            ticks: {
              color: "#E1E1E1",
              fontSize: "20px",
            },
            grid: {
              display: true,
              color: "#7979797D",
              borderDash: [8, 2],
              tickLength: 0,
            },
          },
          y: {
            grid: {
              display: true,
              color: "#7979797D",
              borderDash: [8, 2],
              tickLength: 0,
            },
            beginAtZero: true,
            ticks: {
              color: "#E1E1E1",
              fontSize: "20px",
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      chartDataWeightPerKg: {
        labels: [],
        datasets: [
          {
            label: "Watt/Kg",
            data: [],
            fill: false,
            borderColor: "#ffffff",
            backgroundColor: "#ffffff",
            borderWidth: 2,
          },
          {
            label: "Watt/Kg",
            data: [],
            fill: false,
            borderColor: "#FF6C01",
            backgroundColor: "#FF6C01",
            borderWidth: 2,
          },
          {
            label: "Watt/Kg",
            data: [],
            fill: false,
            borderColor: "#00B2E3",
            backgroundColor: "#00B2E3",
            borderWidth: 2,
          },
        ],
      },
      chartOptionsWeightPerKg: {
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function(tooltipItem) {
                return (
                    "Time: "+ tooltipItem[0]?.raw?.x || ""
                );
              },

            }
          },
          crosshair: false,
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              color: "#E1E1E1",
              fontSize: "20px",
            },

            grid: {
              display: true,
              color: "#7979797D",
              borderDash: [8, 2],
              tickLength: 0,
            },
          },
          y: {
            grid: {
              display: true,
              color: "#7979797D",
              borderDash: [8, 2],
            },
            beginAtZero: true,
            ticks: {
              color: "#E1E1E1",
              fontSize: "20px",
              tickLength: 0,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      modalChartDataGraphElevation: {
        labels: [],
        type: "line",
        datasets: [
          {
            label: "Elevation",
            data: [],
            fill: "start",
            showLine: true,
            borderColor: "#000000",
            backgroundColor: "#e2e6eb",
            borderWidth: 1,
          },
        ],
      },
      modalChartOptionsGraphElevation: {
        elements: {
          point: {
            radius: 0,
          },
        },
        onHover: this.chartHover,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: function(tooltipItem) {
                return (
                    "Distance: "+ (Math.floor(tooltipItem[0]?.raw?.x) / 1000).toFixed(2) + " KM" || ""
                );
              },
              label: function(context) {
                return "Elevation: "+context?.raw.y+" M";
              },
            },
            mode: "nearest",
            intersect: true,
          },
          crosshair: {
            line: {
              color: "#000000", // crosshair line color
              width: 1, // crosshair line width
            },
            sync: {
              enabled: true, // enable trace line syncing with other charts
              group: 1, // chart group
              suppressTooltips: false, // suppress tooltips when showing a synced tracer
            },
            zoom: {
              enabled: false,
            },
          },
        },
        scales: {
          x: {
            beginAtZero: false,
            ticks: {
              fontSize: "20px",
              callback: function(val, index) {

                let parsedValue = (parseFloat(this.getLabelForValue(val)) / 1000).toFixed(2);

                return index % 12 === 0
                    ? parsedValue+" KM"
                    : "";
              },
            },
          },
          y: {
            //  beginAtZero: false,
            min: this.minElevationY, // Set minimum negative value for y-axis
            ticks: {
              fontSize: "20px",
              callback: function(val) {
                if(val <= 0){
                  return val <= 0 && val % 20 === 0 ? val+" M" : '';
                }
                return val+" M"


              }
            }

          },
        },
      },
      modalChartDataGraphPower: {
        type: "line",
        labels: [],
        datasets: [
          {
            label: "Power",
            data: [],
            fill: false,
            borderColor: "#d034d3",
            backgroundColor: "#d034d3",
            borderWidth: 2,
          },
        ],
      },
      modalChartOptionsGraphPower: {
        elements: {
          point: {
            radius: 0,
          },
        },
        onHover: this.chartHover,
        scales: {
          x: {
            ticks: {
              fontSize: "20px",
            },
            display: false,
            beginAtZero: true,
          },

          y: {
            beginAtZero: true,
            ticks: {
              fontSize: "20px",
              callback: function(val) {
                return val+" W"
              }
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "nearest",
            intersect: true,
            callbacks: {
              title: function(tooltipItem) {
                return (
                    "Distance: "+ (Math.floor(tooltipItem[0]?.raw?.x) / 1000).toFixed(2)+ " KM" || ""
                )
              },
              label: function(context) {
                return "Power: "+context?.raw.y+" W";
              }
            },
          },
          crosshair: {
            line: {
              color: "#000000", // crosshair line color
              width: 1, // crosshair line width
            },
            sync: {
              enabled: true, // enable trace line syncing with other charts
              group: 1, // chart group
              suppressTooltips: false, // suppress tooltips when showing a synced tracer
            },
            zoom: {
              enabled: false,
            },
          },
        },
      },
      modalChartDataGraphSpeed: {
        labels: [],
        type: "line",
        datasets: [
          {
            label: "Speed",
            data: [],
            fill: false,
            borderColor: "#1da46e",
            backgroundColor: "#1da46e",
            borderWidth: 1,
          },
        ],
      },
      modalChartOptionsGraphSpeed: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "nearest",
            intersect: true,
            callbacks: {
              title: function(tooltipItem) {
                return (
                    "Distance: "+(Math.floor(tooltipItem[0]?.raw?.x) / 1000).toFixed(2)+ " KM" || ""
                );
              },
              label: function(context) {
                return "Speed: "+context?.raw.y+" KM/H";
              }
            },
          },
          crosshair: {
            line: {
              color: "#000000", // crosshair line color
              width: 1, // crosshair line width
            },
            sync: {
              enabled: true, // enable trace line syncing with other charts
              group: 1, // chart group
              suppressTooltips: false, // suppress tooltips when showing a synced tracer
            },
            zoom: {
              enabled: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            ticks: {
              fontSize: "20px",
            },
            display: false,
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
            ticks: {
              fontSize: "20px",
              callback: function(val) {
                return val+" KM/H"
              }
            }
          },
        },
        onHover: this.chartHover,
        responsive: true,
        maintainAspectRatio: false,
      },
      modalChartDataGraphHeartRate: {
        labels: [],
        type: "line",
        datasets: [
          {
            label: "Heart Rate",
            data: [],
            fill: false,
            borderColor: "#ff5c00",
            backgroundColor: "#ff5c00",
            borderWidth: 1,
          },
        ],
      },
      modalChartOptionsGraphHeartRate: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "nearest",
            intersect: true,
            callbacks: {
              title: function(tooltipItem) {
                return (
                    "Distance: "+(Math.floor(tooltipItem[0]?.raw?.x) / 1000).toFixed(2)+ " KM" || ""
                );
              },
              label: function(context) {
                return "HeartRate: "+context?.raw.y+" BPM";
              }
            },
          },
          crosshair: {
            line: {
              color: "#000000", // crosshair line color
              width: 1, // crosshair line width
            },
            sync: {
              enabled: true, // enable trace line syncing with other charts
              group: 1, // chart group
              suppressTooltips: false, // suppress tooltips when showing a synced tracer
            },
            zoom: {
              enabled: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            ticks: {
              fontSize: "20px",
            },
            display: false,
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
            ticks: {
              fontSize: "20px",
              callback: function(val) {
                return val+" BPM"
              }
            }
          },
        },
        onHover: this.chartHover,
        responsive: true,
        maintainAspectRatio: false,
      },
      modalChartDataGraphCadence: {
        labels: [],
        type: "line",
        datasets: [
          {
            label: "Cadence",
            data: [],
            fill: false,
            borderColor: "#00b2e3",
            backgroundColor: "#00b2e3",
            borderWidth: 1,
          },
        ],
      },
      modalChartOptionsGraphCadence: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "nearest",
            intersect: true,
            callbacks: {
              title: function(tooltipItem) {
                return (
                    "Distance: "+ (Math.floor(tooltipItem[0]?.raw?.x) / 1000).toFixed(2)+ " KM" || ""
                );
              },
              label: function(context) {
                return "Cadence: "+context?.raw.y+" RPM";
              }
            },
          },
          crosshair: {
            line: {
              color: "#000000", // crosshair line color
              width: 1, // crosshair line width
            },
            sync: {
              enabled: true, // enable trace line syncing with other charts
              group: 1, // chart group
              suppressTooltips: false, // suppress tooltips when showing a synced tracer
            },
            zoom: {
              enabled: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        onHover: this.chartHover,
        scales: {
          x: {
            ticks: {
              fontSize: "20px",
            },
            display: false,
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
            ticks: {
              fontSize: "20px",
              callback: function(val) {
                return val+" rpm"
              }
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      instanceChart: null,
      instanceWeightChart: null,
      instanceElevation: null,
      instancePower: null,
      instanceSpeed: null,
      instanceHeartRate: null,
      instanceCadence: null,
      page: 1,
      type: "",
      finished: false,
      sortDate: "DESC",
      isActiveProfileTab: true,
      isActiveActivityTab: false,
      isActiveInGameShopTab: false,
    };
  },
  components: {
    GameShop,
    OverView,
    LineChartGenerator,
  },

  computed: {
    ...mapGetters("user", [
      "activities",
      "compare_data",
      "single_ride_data",
      "rider_default_data",
        'user_total_event_count'
    ]),

    chartDataElevation() {
      return this.modalChartDataGraphElevation;
    },
    chartOptionsElevation() {
      return this.modalChartOptionsGraphElevation;
    },
    chartDataPower() {
      return this.modalChartDataGraphPower;
    },
    chartOptionsPower() {
      return this.modalChartOptionsGraphPower;
    },
    chartDataSpeed() {
      return this.modalChartDataGraphSpeed;
    },
    chartOptionsSpeed() {
      return this.modalChartOptionsGraphSpeed;
    },
    chartDataHeartRate() {
      return this.modalChartDataGraphHeartRate;
    },
    chartOptionsHeartRate() {
      return this.modalChartOptionsGraphHeartRate;
    },
    chartDataCadence() {
      return this.modalChartDataGraphCadence;
    },
    chartOptionsCadence() {
      return this.modalChartOptionsGraphCadence;
    },
    chartDataWeightPerKgCompute() {
      return this.chartDataWeightPerKg;
    },
    chartOptionsWeightPerKgCompute() {
      return this.chartOptionsWeightPerKg;
    },
    chartDataCompute() {
      return this.chartDataDefault;
    },
    chartOptionsCompute() {
      return this.chartOptions;
    },

  },

  async mounted() {
    this.showTab();
    await this.getAllActivities();
    await this.getDefaultData();
  },
  methods: {
    makeTabFunc(evt) {
      const tabclass = document.querySelector(".main-tab-container");

      if (evt.target.attributes.id.value === "nav-home-tab") {
        tabclass.classList.remove("container-fluid");
        tabclass.classList.add("container");
      } else if (evt.target.attributes.id.value === "nav-contact-tab3") {
        tabclass.classList.remove("container");
        tabclass.classList.add("container-fluid");
      }
    },
    showTab() {
      const hash = window.location.hash;

      if (hash === "#profile") {
        this.isActiveProfileTab = true;
        this.isActiveActivityTab = false;
        document.getElementById("nav-home-tab").click();
      } else if (hash === "#activities") {
        this.isActiveProfileTab = false;
        this.isActiveActivityTab = true;
        document.getElementById("nav-contact-tab3").click();
      } else if (hash === "#in-game-shop") {
        this.isActiveProfileTab = false;
        this.isActiveActivityTab = false;
        this.isActiveInGameShopTab = true;
        document.getElementById("nav-in-game-shop-tab3").click();
      } else {
        this.isActiveProfileTab = true;
        this.isActiveActivityTab = false;
        this.isActiveInGameShopTab = false;
      }
    },
    addToGraph(evt, fileId) {
      if (this.graphFileIds.indexOf(fileId) === -1) {
        evt.target.classList.add("active");
        this.graphFileIds.push(fileId);
      } else {
        evt.target.classList.remove("active");
        this.graphFileIds.splice(this.graphFileIds.indexOf(fileId), 1);
      }
    },
    chartHover(evt, activeEls, chart) {
      let indexData;
      if (
          activeEls.length === 0 ||
          chart.getElementsAtEventForMode(
              evt,
              "nearest",
              {
                intersect: true,
              },
              true
          ).length === 0
      ) {
        return;
      }

      const finalIndex = chart.getElementsAtEventForMode(
          evt,
          "nearest",
          {
            intersect: true,
          },
          true
      )[0].index;

      indexData = this.modalChartDataGraphElevation.datasets[0].data[
          finalIndex
          ];
      this.hoverElevationDate = moment
          .utc(indexData["time"] * 1000)
          .format("HH:mm:ss");
      this.hoverElevationTime = moment
          .utc(indexData["time"] * 1000)
          .format("mm");
      this.hoverElevation = (parseFloat(indexData.x) / 1000).toFixed(2);

      indexData = this.modalChartDataGraphPower.datasets[0].data[finalIndex];
      this.hoverPower = indexData?.y.toFixed(2);

      indexData = this.modalChartDataGraphSpeed.datasets[0].data[finalIndex];
      this.hoverSpeed = indexData?.y.toFixed(2);

      indexData = this.modalChartDataGraphHeartRate.datasets[0].data[
          finalIndex
          ];
      this.hoverHeartRate = indexData?.y.toFixed(2);

      indexData = this.modalChartDataGraphCadence.datasets[0].data[finalIndex];
      this.hoverCadence = indexData?.y.toFixed(2);
    },

    toDaysMinutesSeconds(seconds) {
      var duration = moment.duration(seconds, "seconds");

      var sec = duration.asSeconds();
      var min = duration.asMinutes();
      var hrs = duration.asHours();

      if (sec <= 60) {
        return Math.floor(sec) + "s";
      } else {
        if (min <= 60) {
          return Math.floor(min) + "mn";
        } else {
          return Math.floor(hrs) + "h";
        }
      }
    },
    getSingleActivity(fileId, fileDate) {
      let that = this;

      this.$store.dispatch("user/get_single_ride_data", { fileId })
          .then(() => {
            if(that.single_ride_data.detail.length >0 ){
              const dist = that.single_ride_data.detail
                  .map((val) => Math.round(val.distance) + "KM")
                  .filter((distance, index, arr) => arr.indexOf(distance) === index);

              that.selectedFileId = fileId;
              that.selectedDate = fileDate;
              that.modalChartDataGraphElevation["labels"] = dist;
              that.modalChartDataGraphPower["labels"] = dist;
              that.modalChartDataGraphSpeed["labels"] = dist;
              that.modalChartDataGraphHeartRate["labels"] = dist;
              that.modalChartDataGraphCadence["labels"] = dist;
              //
              that.modalChartDataGraphElevation["datasets"][0][
                  "data"
                  ] =  that.single_ride_data.detail.map((val, index) => ({
                x: val.distance,
                y: val.altitude,
                time: index,
              }));


              this.minElevationY = this.lodash
                  .minBy(that.modalChartDataGraphElevation["datasets"][0]["data"], "y")
                  .y.toFixed();

              that.modalChartDataGraphPower["datasets"][0][
                  "data"
                  ] = that.single_ride_data.detail.map((val) => ({
                x: val.distance,
                y: val.power,
              }));

              this.maxPower = this.lodash
                  .maxBy(that.modalChartDataGraphPower["datasets"][0]["data"], "y")
                  .y.toFixed();
              this.minPower = this.lodash
                  .minBy(that.modalChartDataGraphPower["datasets"][0]["data"], "y")
                  .y.toFixed();
              this.avgPower = this.lodash
                  .meanBy(that.modalChartDataGraphPower["datasets"][0]["data"], "y")
                  .toFixed();

              that.modalChartDataGraphSpeed["datasets"][0][
                  "data"
                  ] = that.single_ride_data.detail.map((val) => ({
                x: val.distance,
                y: val.speed,
              }));

              this.maxSpeed = this.lodash
                  .maxBy(that.modalChartDataGraphSpeed["datasets"][0]["data"], "y")
                  .y.toFixed();
              this.avgSpeed = this.lodash
                  .meanBy(that.modalChartDataGraphSpeed["datasets"][0]["data"], "y")
                  .toFixed();

              that.modalChartDataGraphHeartRate["datasets"][0][
                  "data"
                  ] = that.single_ride_data.detail.map((val) => ({
                x: val.distance,
                y: val.heartRate,
              }));

              this.maxHeartRate = this.lodash
                  .maxBy(that.modalChartDataGraphHeartRate["datasets"][0]["data"], "y")
                  .y.toFixed();


              this.minHeartRate = this.lodash
                  .minBy(that.modalChartDataGraphHeartRate["datasets"][0]["data"], "y")
                  .y.toFixed();
              this.avgHeartRate = this.lodash
                  .meanBy(that.modalChartDataGraphHeartRate["datasets"][0]["data"], "y")
                  .toFixed();

              that.modalChartDataGraphCadence["datasets"][0][
                  "data"
                  ] = that.single_ride_data.detail.map((val) => ({
                x: val.distance,
                y: val.cadence,
              }));

              this.maxCadence = this.lodash
                  .maxBy(that.modalChartDataGraphCadence["datasets"][0]["data"], "y")
                  .y.toFixed();
              this.avgCadence = this.lodash
                  .meanBy(that.modalChartDataGraphCadence["datasets"][0]["data"], "y")
                  .toFixed();

              this.showDetail = true;
            }
            else{
              global.app.$toastr.error("Unable to fetch activity detail.");
            }

          });
    },
    hideDetail() {
      this.showDetail = false;
    },
    selectTab(title) {
      this.tabTitle = title;
    },
    async getDefaultData() {
      let that = this;
      await this.$store
          .dispatch("user/get_rider_default_data")
          .then(function() {
            let timeArr = that.rider_default_data?.overall_average_graph?.power.map(
                (val) => val.x
            );
            that.chartDataDefault["labels"] = timeArr;
            that.chartDataWeightPerKg["labels"] = timeArr;
            that.chartDataDefault["datasets"][0]["data"] =
                that.rider_default_data?.overall_average_graph?.power;
            that.chartDataWeightPerKg["datasets"][0]["data"] =
                that.rider_default_data?.overall_average_graph?.power_weight;
          });
    },
    getAllActivities() {
      this.$store
          .dispatch("user/new_activities", {
            type: this.type,
            page: this.page,
            sortDate: this.sortDate,
          })
          .then((res) => {
            if (!res || res.error) {
              return;
            }
            if (this.activities.currentPage === this.activities.totalPages) {
              this.finished = true;
              return;
            }
            this.page++;
          });
    },
    async downloadFile(fileId) {
      let { data } = await this.$store.dispatch("user/downloadFile", {
        fileId,
      });

      const docUrl = document.createElement("a");
      docUrl.href = data;
      docUrl.setAttribute("download", fileId);
      document.body.appendChild(docUrl);
      docUrl.click();
    },
  },
  watch: {
    $route() {
      this.showTab();
      this.showDetail = false;
    },
    //this function might be removed later
    async graphFileIds() {
      this.chartDataWeightPerKg["datasets"][1]["data"] = [];
      this.chartDataDefault["datasets"][1]["data"] = [];
      this.chartDataWeightPerKg["datasets"][2]["data"] = [];
      this.chartDataDefault["datasets"][2]["data"] = [];
      this.firstGraphDate = "";
      this.secondGraphDate = "";

      if (this.graphFileIds.length > 2) {
        this.graphFileIds.shift();

        Array.from(
            document.querySelectorAll(".input_compare")
        )[0].classList.remove("active");
      }

      if (this.graphFileIds.length === 0) {
        this.getDefaultData();
      }

      if (this.graphFileIds.length > 0 && this.graphFileIds.length <= 2) {
        await this.$store
            .dispatch("user/get_compare_data", {
              activityFileIds: this.graphFileIds,
            })
      }
    },
    type() {
      this.finished = false;
      this.page = 1;
      this.getAllActivities();
    },
    sortDate() {
      this.finished = false;
      this.page = 1;
      this.getAllActivities();
    },
  },

}
</script>

<style scoped>

@media (min-width: 1920px) {
  .container {
    max-width: 1600px;
    margin: 0 auto;
  }
}
</style>
