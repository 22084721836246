import FrontLayout from '@/layouts/FrontLayout'
// import Home from '@/views/pages/Home'
import Routes from '@/views/pages/Routes'
import Events from '@/views/pages/Events'
import Event from '@/views/pages/JoinEvent'
import EventDetails from '@/views/pages/EventDetails'
import Support from '@/views/pages/Support'
import ContactUs from '@/views/pages/ContactUs'
import Terms from '@/views/pages/Terms'
import Faqs from '@/views/pages/Faqs'
import AboutUs from "@/views/pages/AboutUs";
import TeamUae from "@/views/pages/TeamUae";
import TeamAue from "@/views/pages/TeamAue";
import Privacy from "@/views/pages/Privacy";
import Article from "@/views/pages/Article";
import Ruleset from "@/views/pages/Ruleset";
import Login from "@/views/auth/Login";
import Profile from "@/views/user/Profile";
import Results from "@/views/user/Results";
import Register from "@/views/auth/Register";
import Verification from "@/views/auth/Verification";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ForgotPassword2 from "@/views/auth/ForgotPassword2";
import VerifyJavaUser from "@/views/auth/verifyJavaUser";
import DeleteUser from "@/views/auth/DeleteUser";
import EventThankYou from '@/views/pages/EventThankYou'
import fitnessNetworkCallback from "@/views/oauth/fitnessNetworkCallback";
import fitnessNetworkAuthorize from "@/views/oauth/fitnessNetworkAuthorize";
import IframeWarning from '@/components/IframeWarning.vue'
import AlUla from "../views/pages/AlUla";
import NewActivities from "@/views/user/NewActivities";

export default [
    {
        path: '/',
        component: FrontLayout,
        meta: { requiresAuth: false },
        children: [
            { path: '', name: 'home', component: Events },
            { path: 'home', name: 'home', component: Events },
            { path: 'routes', name: 'routes', component: Routes },
            {
                path: '/iframe-warning',
                component: IframeWarning
            },
            {
                path: 'events',
                name: 'events',
                component: Events,
                // beforeEnter() {
                    // window.location.href = `${process.env.VUE_APP_WEB_URL}/events`;
                // }
            },
            { path: 'event/:id', name: 'event', component: Event },
            { path: 'event/:id/thank-you', name: 'EventThankYou', component: EventThankYou },
            { path: 'event/detail/:id', name: 'event-details', component: EventDetails },
            { path: 'support', name: 'support', component: Support },
            { path: 'contact-us', name: 'contacts', component: ContactUs },
            { path: 'terms-and-conditions', name: 'terms', component: Terms },
            { path: 'faqs', name: 'faqs', component: Faqs },
            { path: 'about-us', name: 'about', component: AboutUs },
            { path: 'team-uae', name: 'team-uae', component: TeamUae },
            { path: 'sunday-race-club', name: 'sunday-race-club', component: TeamAue },
            { path: 'al-ula', name: 'team-uae', component: AlUla },
            { path: 'privacy-policy', name: 'privacy', component: Privacy },
            { path: 'article/:slug', name: 'article', component: Article },
            { path: 'mywhoosh-ruleset', name: 'Ruleset', component: Ruleset },
            { path: '/verify-user', name: 'VerifyJavaUser', component: VerifyJavaUser },
            { path: '/delete-user/:code', name: 'DeleteUser', component: DeleteUser },
        ]
    },
    {
        path: '/auth',
        component: FrontLayout,
        meta: { requiresAuth: false },
        children: [
            { path: 'login', name: 'login', component: Login },
            { path: 'register', name: 'register', component: Register },
            { path: 'verification', name: 'verification', component: Verification },
            { path: 'forgot-password', name: 'forgetpassword', component: ForgotPassword },
            { path: 'forgot-password2', name: 'forgotpassword2', component: ForgotPassword2 },
        ]
    },


    // 3rd party oAuth2.0 e.g, trainerDays
    {
        path: '/oauth/authorize',
        name: 'fitnessNetworkAuthorize',
        component: fitnessNetworkAuthorize
    },


    // 3rd party oAuth authorization e.g, strava/training_peaks
    {
        path: '/oauth/:network/:source?',
        name: 'fitnessNetworkCallback',
        component: fitnessNetworkCallback
    },
    {
        path: '/',
        component: FrontLayout,
        meta: { requiresAuth: false },
        // beforeEnter: auth,
        children: [
            { path: 'user/profile', name: 'profile', component: Profile },
            { path: 'user/activities', name: 'new_activities', component: NewActivities },
            { path: 'race-results', name: 'results', component: Results },
        ]
    },

    { path: '*', redirect: '/' }
];
