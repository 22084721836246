export const commonMixin = {
  methods: {
    assetsUrl() {
      return process.env.VUE_APP_ASSETS;
    },

    preVerificationUrl(eventId) {
      return process.env.VUE_APP_WEB_URL + `/weightvideo/?event-id=${eventId}`;
    },

    wpWebUrl() {
      return process.env.VUE_APP_WEB_URL;
    },

    makeImage(url, w = 800, h = 600, fit = false) {
      if (fit) {
        return `${url}/${w}/${h}/fit`;
      }

      return `${url}/${w}/${h}`;
    },
  },
};