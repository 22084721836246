<template>
  <div>
    <vue-title
      :title="`${getEventData.name} event participation registration`"
    ></vue-title>
    <div class="homepage-wrap clearfix">
      <div class="section-wrap bg-img register-banner">
        <div class="max-width-wrap">
          <div class="container">
            <div class="breadcrumb">
              <ul>
                <li>Event</li>
                <li>{{ getEventData.name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="event-register events-page-wrap clearfix"  v-if="loggedIn">
      

      <div class="container">
        <div class="row">
          <section class="col-md-12">
            <div class="col-left ml-2 registration-wizard" >
              <label>REGISTER</label>
            </div>

            <!-- <pre>Can Participate: {{ checkEligibility() }}</pre> -->
            <div v-if="!checkEligibility()" class="register-text">
              <p class="alert alert-warning text-center p-3">
                <strong>{{ eventMessage }}</strong>
              </p>
            </div>

            <div v-if="!loggedIn" class="register-text">
              <p class="text-danger text-center">
                <strong>Please login to participate in the event</strong>
              </p>
            </div>

            <div v-else-if="loggedIn
                      && (this.user.gender === this.getEventData.gender
                      || this.getEventData.gender === 2) && checkEligibility()"
                 class="register-text mb-0 ml-2">
              <template
                v-if="
                  !getEventData.ustatus || getEventData.ustatus === 'declined'
                "
              >
                <template
                  v-if="
                      getEventData &&
                      getEventData.registration &&
                      getEventData.accept &&
                      checkEmptySlots()
                  "
                >
                  <section
                    class="container bg-white rounded border p-0"
                    v-if="getEventData"
                  >
                    <form-wizard
                      shape="tab"
                      stepSize="sm"
                      @on-complete="submitApplication"
                      color="#fbb814"
                    >
                      <tab-content title="DISCLAIMER">
                        <div class="container p-5">
                          Hello, registration for this event will require you to
                          input personal details about your physique and
                          performance. Please make every effort to enter
                          accurate information to ensure fair racing.
                        </div>
                      </tab-content>

                      <tab-content
                        title="PERSONAL DETAILS"
                        :before-change="() => validateStep('personal')"
                      >
                        <EventPersonalForm
                          ref="personal"
                          :user="getUserData"
                          :dobRequired="getEventData.dob_required"
                          @on-validate="mergePartialModels"
                        />
                      </tab-content>

                      <tab-content
                        title="RIDER DETAILS"
                        :before-change="() => validateStep('physical')"
                      >
                        <EventPhysicalForm
                          ref="physical"
                          lazy="true"
                          v-if="getEventData"
                          :event="getEventData"
                          :user="getUserData"
                          @on-validate="mergePartialModels"
                        />
                      </tab-content>

                      <tab-content
                        title="IDENTITY VERIFICATION"
                        :before-change="() => validateStep('identity')"
                        v-if="getEventData.identification"
                      >
                        <EventIdentificationForm
                          ref="identity"
                          lazy="true"
                          :event="getEventData"
                          :user="getUserData"
                          @on-validate="mergePartialModels"
                        />
                      </tab-content>

                      <tab-content
                        title="DEVICE DETAILS"
                        :before-change="() => validateStep('device')"
                        v-if="getEventData.device"
                      >
                        <EventDeviceForm
                          ref="device"
                          lazy="true"
                          :event="getEventData"
                          :user="getUserData"
                          @on-validate="mergePartialModels"
                        />
                      </tab-content>

                      <tab-content
                        title="TEAM"
                        :before-change="() => validateStep('team')"
                        v-if="getEventData.team"
                      >
                        <EventTeamForm
                          ref="team"
                          lazy="true"
                          :event="event"
                          v-if="getEventData"
                          @on-validate="mergePartialModels"
                        />
                      </tab-content>

                      <tab-content
                        title="PHONE VERIFICATION"
                        :before-change="() => validateStep('otp')"
                        v-if="getEventData.otp"
                      >
                        <EventOtpForm
                          ref="otp"
                          lazy="true"
                          v-if="getEventData"
                          :event="getEventData"
                          :user="getUserData"
                          @on-validate="mergePartialModels"
                        />
                      </tab-content>
                    </form-wizard>

                    <div class="text-danger bottom-error pb-3" v-html="getErrors"></div>
                  </section>
                </template>
              </template>
            </div>

          </section>
        </div>
      </div>
    </section>

    <Login v-else></Login>

    <PriceWidget
      v-if="getWinnersData.length > 0 && checkEligibility()"
      :winners="getWinnersData"
      :eventData="getEventData"
      :showLink="true"
    />

  </div>

</template>

<script>
import { mapGetters } from "vuex";
import * as dayjs from "dayjs";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import EventTeamForm from "@/components/event/participationForm/TeamForm";
import EventPersonalForm from "@/components/event/participationForm/PersonalForm";
import EventPhysicalForm from "@/components/event/participationForm/PhysicalForm";
import EventIdentificationForm from "@/components/event/participationForm/IdentificationForm";
import EventDeviceForm from "@/components/event/participationForm/DeviceForm";
import EventOtpForm from "@/components/event/participationForm/EventOtpForm";
import PriceWidget from "@/components/event/PriceWidget";
import Login from "@/views/auth/Login";
import Swal from "sweetalert2";

export default {
  name: "Event",
  components: {
    FormWizard,
    TabContent,
    EventTeamForm,
    EventPersonalForm,
    EventPhysicalForm,
    EventIdentificationForm,
    EventDeviceForm,
    EventOtpForm,
    PriceWidget,
    Login
  },

  data() {
    return {
      formData: new FormData(),
      modalShow: false,
      eventId: this.$route.params.id,
      submitted: false,
      formIsValid: true,
      instruction: false,
      charLimit: 20,
    };
  },

  filters: {
    dateFormat: function(value) {
      return dayjs(value).format("dddd, DD MMMM YYYY");
    },

    timeFormat: function(value) {
      return dayjs(value).format("hh:mm A");
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("page", ["page"]),
    ...mapGetters("auth", ["loggedIn", "user"]),
    ...mapGetters("event", ["event", "otpSentStatus", "otpVerifyStatus"]),

    getEventData() {
      if (!this.event || !this.event.event) return false;
      return Object.assign({}, this.event.event);
    },

    getWinnersData() {
      if (!this.getEventData) return [];
      return Object.assign([], this.getEventData.winners);
    },

    getUserData() {
      if (!this.getEventData) return false;
      return Object.assign({}, this.event.user);
    },

    getErrors() {
      if (!this.errors) return;

      let error_html = " ";

      if(this.errors.length > 0) {
        for (const element of Object.values(this.errors)) {
          error_html += "<p>" + element + "</p>";
        }
        error_html += " <p>For further information please contact " +
            "<a href='mailto:support@mywhoosh.com'>support@mywhoosh.com</a></p>";
      }

      return error_html;
    },
  },

  mounted() {
    document.body.classList.add("contact");
    this.$store.dispatch("auth/loggedIn");
    this.$store.dispatch("page/view", "events");
    this.$store.dispatch("event/event", this.eventId).then((res) => {
      if (res.data?.is_registered) {
        this.$router.push({ name: "events" });
      }
    });
  },
  watch: {
    getErrors: function(val) {
      if (Object.values(this.errors).length > 0) {
        Swal.fire({
          icon: "error",
          html: val,
        });
      }
    },
  },

  methods: {
    checkEligibility() {
      if ((this.getUserData.gender == this.getEventData.gender) || this.getEventData.gender == 2) {
        return true;
      }

      let gender = [{id: 1, "label": "Males"}, {id: 2, "label": "Females"}];
      this.eventMessage = "This is a " + gender.find(g => g.id == this.getEventData.gender)?.label + " only event."
      return false;
    },


    validateStep(name) {
      return this.$refs[name].validate();
    },

    mergePartialModels(model, isValid) {
      if (isValid) {
        for (const [key, value] of Object.entries(model)) {
          this.formData.set(key, value);
        }
      }
    },
    getUserNameForMenu() {
      if (this.user) {
        return this.user.firstname + " " + this.user.lastname;
      }
      return null;
    },
    makeQueryString() {
      if (!this.user) return `?login=false`;
      return `?login=${this.loggedIn}&profile=${this.getUserNameForMenu}`;
    },

    checkEmptySlots() {
      return (
        this.getEventData.participants.booked <
        this.getEventData.participants.capacity
      );
    },

    submitApplication() {
      this.submitted = true;
      if (this.getEventData.otp) {
        if (!this.otpVerifyStatus) {
          this.formIsValid = false;
          return;
        }
      }

      this.formData.append("eventId", this.eventId);
      this.$store
        .dispatch("event/submitApplication", this.formData)
        .then((res) => {
          if (res && res.status) {
            this.$router.push({
              name: "EventThankYou",
              params: { id: this.eventId },
            });
          }
        }) ;
    },
  },

  destroyed() {
    document.body.classList.remove("event-registration");
  },
};
</script>

<style scoped>
.register-text {
  margin-top: 0px;
}
section h6 {
  color: #ccc;
  background: white;
  padding: 7px 10px 5px;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
}
.registration-wizard label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #000000;
  vertical-align: middle;
}
.breadcrumb {
  margin-bottom: 0px;
}

</style>
