import Vue from 'vue'
import App from '@/App.vue'
import VueRouter from 'vue-router'
import store from '@/store'; // vuex store
import TitleComponent from '@/components/shared/TitleComponent';

import Vuelidate from 'vuelidate'
import VueToastr2 from 'vue-toastr-2'
import VueLazyload from 'vue-lazyload'
import { Datetime } from 'vue-datetime'
import vueSelect from 'vue-select'
import VueTelInput from 'vue-tel-input';

import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
import 'vue-datetime/dist/vue-datetime.css'
import 'vue-select/dist/vue-select.css';
import VueHead from 'vue-head'
import VueMoment from 'vue-moment'

// scroll animation
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
// Datepicker
window.toastr = require('toastr')

Vue.use(VueTelInput, {
    dropdownOptions: {
        showDialCodeInSelection: true,
        showDialCodeInList: true
    }
})
 
// configs
Vue.config.productionTip = false
Vue.use(VueLodash, {   lodash: lodash })
// use statements
Vue.use(VueMoment)
Vue.use(VueHead)
Vue.use(VueRouter)
Vue.use(Datetime)
Vue.use(VueToastr2)
Vue.use(Vuelidate)
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/assets/images/box-1.png',
    loading: '/assets/images/loading.gif',
    attempt: 1
});

// register components
Vue.component('vue-title', TitleComponent);
Vue.component('v-select', vueSelect);



// routes
import routes from '@/routes/index';
const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});
router.beforeEach((to, from, next) => {

    // Check if the page is being loaded within an iframe
    if (window !== window.top) {
        // If the page is loaded within an iframe, redirect the user to the main page
      next({ path: '/iframe-warning' });
    } else {
        // Continue to the next route

        next();
    }
})

Vue.config.devtools = false;
const app = new Vue({
    created () {
        AOS.init()
    },
    store,
    router,
    render: h => h(App),
}).$mount('#app')

global.app = app;
