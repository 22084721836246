var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"shop_payment_modal",attrs:{"modal-class":"payment","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v("Shop")]),_c('button',{staticClass:"close",attrs:{"aria-label":"Close","type":"button"},on:{"click":_vm.hideModal}},[_c('img',{attrs:{"src":"/assets/images/shop/icons/close2.png","alt":""}})])]},proxy:true},{key:"default",fn:function(){return [(_vm.showLoading)?_c('LoadingIcon',{ref:"loading_msg"}):_vm._e(),(_vm.showSuccessMsg)?_c('MessageSuccess',{ref:"success_msg_component",attrs:{"coins_purchased":_vm.coins_purchased,"gems_purchased":_vm.gems_purchased}}):_vm._e(),(_vm.showFailureMsg)?_c('MessageError',{ref:"error_msg_component",attrs:{"coins_purchased":_vm.coins_purchased,"gems_purchased":_vm.gems_purchased}}):_vm._e(),(_vm.showPackages)?_c('b-tabs',{ref:"package_section",on:{"input":_vm.handleTabUpdate}},[_c('b-tab',{attrs:{"title":"Coins","active":_vm.localActiveTab === 0}},[_c('b-row',_vm._l((_vm.coins_bundles),function(item,index){return _c('b-col',{key:index + 'gems',staticClass:"px-2 mb-3",attrs:{"sm":"6","lg":"4"}},[_c('b-card',{on:{"click":function($event){return _vm.purchaseGems(
                                        item.Identifier,
                                        item.Price
                                    )}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('img',{attrs:{"src":"/assets/images/shop/icons/coin.png","alt":"Coins"}}),_c('span',[_vm._v(_vm._s(item.Reward))])]},proxy:true}],null,true)},[_c('img',{attrs:{"src":`/assets/images/shop/icons/coins-${
                                        index + 1
                                    }x.png`,"alt":"Coins"}}),_c('div',{staticClass:"card-cta"},[(false)?_c('span',{staticClass:"ex-price"},[_c('img',{attrs:{"src":"/assets/images/shop/icons/gem.png","alt":"Gems"}}),_vm._v(" 600 ")]):_vm._e(),_c('span',{staticClass:"price"},[_c('img',{attrs:{"src":"/assets/images/shop/icons/gem.png","alt":"Gems"}}),_c('span',[_vm._v(_vm._s(item.Price))]),(_vm.userGems < item.Price)?_c('small',{staticStyle:{"color":"red"}},[_vm._v("Not Enough Gems")]):_vm._e()])]),(false)?_c('strong',{staticClass:"offer"},[_vm._v("Limited Time")]):_vm._e()])],1)}),1)],1),_c('b-tab',{attrs:{"title":"Power Gems","active":_vm.localActiveTab === 1}},[_c('b-row',_vm._l((_vm.gems_bundles),function(item,index){return _c('b-col',{key:index + 'coins',staticClass:"px-2 mb-3",attrs:{"sm":"6","lg":"4"}},[_c('b-card',{on:{"click":function($event){return _vm.getRedirectUrl(item.Identifier)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('img',{attrs:{"src":"/assets/images/shop/icons/gem.png","alt":"Gems"}}),_c('span',[_vm._v(_vm._s(item.Reward))])]},proxy:true}],null,true)},[_c('img',{attrs:{"src":`/assets/images/shop/icons/gems-${
                                        index + 1
                                    }x.png`,"alt":"Gems"}}),_c('div',{staticClass:"card-cta"},[(false)?_c('span',{staticClass:"ex-price"},[_vm._v("AED 350")]):_vm._e(),_c('span',{staticClass:"price"},[_vm._v("AED "+_vm._s(item.Price))])]),(false)?_c('strong',{staticClass:"offer"},[_vm._v("Limited Time")]):_vm._e()])],1)}),1)],1)],1):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(){return undefined},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }